.App {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
  max-width: 450px;
}
.bg {
  background: radial-gradient(circle, #98BFD6 0%, #203C67 100%);
  height: 100%;
  min-height: 100vh;
}

.fastLogo img {
  width: 200px;
}
.countrySelector {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.countrySelector h3 {
  margin-top: 0;
  color: white;
  text-align: center;
}
.countrySelector select {
  width: 300px;
  height: 50px;
  background: #132e46;
  color: white;
  font-size: 20px;
}

.contactsReturned {
  margin-top: 5%;
  color: white;
}
.contactsReturned a {
  color: white;
}
.heroesFooter img {
  width: 100%;
  margin-top: 50px;
}
.contactDetails {
  margin: 10px;
}

@media only screen and (min-width: 500px) {
  .App {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 3.5%;
  }
}